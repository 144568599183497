import React, { useState, useEffect } from "react"
import { images } from "../../../static/index"
import Hero from "./Hero"
import DevelpmentServices from "./DevelpmentServices/DevelopmentServices"
import "./CustomSoftware.scss"
import Head from "../utils/Head"
import {
  CustomSoftwareItemsSliders,
  updatedCustomSoftware,
} from "../shared/ModalSlider/data"
import Clutch from "../Homepage/Clutch"
const ModalSlider = React.lazy(() => import("../shared/ModalSlider"))
const WorkSwiper = React.lazy(() =>
  import("../../components/Homepage/WorkSwiper")
)
const CustomSoftware = React.lazy(() => import("./CustomSoftware/Content"))
const ClientSection = React.lazy(() => import("./ClientSection"))
const Reviews = React.lazy(() => import("../../components/Homepage/Reviews"))
const ServicesSection = React.lazy(() => import("./ServicesSection"))
const Accordian = React.lazy(() => import("./FAQs/Accordian"))
const ContactUs = React.lazy(() => import("../ProductDesign/Footer/ContactUs"))

function CustomSoftwareContent() {
  const [showReview, setShowReview] = useState(false)
  useEffect(() => {
    setShowReview(true)
  }, [])
  return (
    <div>
      <Head
        metaTitle="Custom Software Development - CodeFulcrum"
        title=" Customized Software Development for Your Needs - CodeFulcrum"
        desc="Transform your business operations with our bespoke custom application development services. With a focus on tailored solutions, we deliver cutting-edge software solutions designed to elevate your productivity."
      />

      <Hero
        id="top"
        btnText="SHARE YOUR REQUIREMENTS"
        img={images.portfolio.PortfolioHero}
        header="Making Your World Better, One Line At A Time"
        text="Let us know your requirements, and we will propose the best solution, select the technology, create the product, and take care of its subsequent maintenance. We guarantee high-quality products delivered on time, scope, and budget."
        scroll="#d-services"
      />
      <DevelpmentServices />

      <React.Suspense fallback={<div />}>
        <ModalSlider
          page="customsoftwaredevelopment"
          data={updatedCustomSoftware}
          title={"Here’s how we build your custom software"}
          description={`Our development team adopts an agile methodology, which allows them
          to focus on the priorities that will deliver the highest business
          value at each stage of the process.`}
        />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <WorkSwiper />
      </React.Suspense>

      <div>
        <React.Suspense fallback={<div />}>
          <CustomSoftware />
        </React.Suspense>
        <React.Suspense fallback={<div />}>
          <ClientSection />
        </React.Suspense>
      </div>
      {showReview && (
        <React.Suspense fallback={<div />}>
          {/* <Reviews /> */}
          <Clutch />
        </React.Suspense>
      )}
      <React.Suspense fallback={<div />}>
        <ServicesSection />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <Accordian />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ContactUs type="CustomSoftware" />
      </React.Suspense>
    </div>
  )
}

export default CustomSoftwareContent
