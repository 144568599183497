import React from "react"
import "./DevelopmentServices.scss"
import { Box } from "@mui/material"
import { Grid } from "@mui/material"
function DevelopmentServices() {
  const [hover, setHover] = React.useState({
    box1: false,
    box2: true,
    box3: false,
  })
  return (
    <div className="cards-csd" id="d-services">
      <div>
        <div className="cards-bg">
          <Grid
            container
            justifyContent="center"
            columnSpacing={{ md: 5, sm: 3, xs: 0 }}
            rowSpacing={{ md: 8, sm: 8, xs: 2 }}
            // rowSpacing={4}
          >
            <Grid item md={4} sm={6} xs={12}>
              <div data-aos="zoom-in" data-aos-duration="1200">
                <Box
                  className={!hover.box1 ? "box" : "box-hover-csd"}
                  onMouseEnter={() => {
                    setHover({
                      ...hover,
                      box1: true,
                      box2: false,
                      box3: false,
                    })
                  }}
                >
                  <p className="header">Startups</p>
                  <p className="text">
                    We take your idea through prototyping to MVP development to
                    full-scale launch of your product.
                  </p>
                  <div className="list">
                    <p>1. MVP creation and prototyping</p>
                    <p>2. Fast time-to-market</p>
                    <p>3. Advice on technology stack</p>
                  </div>
                </Box>
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div data-aos="zoom-in" data-aos-duration="1200">
                <Box
                  className={hover.box2 ? "box-hover-csd" : "box"}
                  onMouseEnter={() => {
                    setHover({
                      ...hover,
                      box1: false,
                      box2: true,
                      box3: false,
                    })
                  }}
                >
                  <p className="header">Enterprises</p>
                  <p className="text">
                    We have over a decade of experience ensuring success of
                    large enterprises’ tech initiatives.
                  </p>
                  <div className="list">
                    <p> 1. Modernisation of legacy software</p>
                    <p>2. Interdepartmental workflows</p>
                    <p>3. Regulatory compliance</p>
                    <p>4. Dedicated support team </p>
                  </div>
                </Box>
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div data-aos="zoom-in" data-aos-duration="1200">
                <Box
                  className={hover.box3 ? "box-hover-csd" : "box"}
                  onMouseEnter={() => {
                    setHover({
                      ...hover,
                      box1: false,
                      box2: false,
                      box3: true,
                    })
                  }}
                >
                  <p className="header">SMEs</p>
                  <p className="text">
                    We work with you to define, visualize, and build software
                    tailored to your business needs.
                  </p>
                  <div className="list">
                    <p> 1. Business need analysis</p>
                    <p> 2. Early risk mitigation</p>
                    <p> 3. Flexibility and scalability</p>
                    <p> 4. Budgetary control</p>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default DevelopmentServices
