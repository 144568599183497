import React, { useEffect } from "react"
import CustomSoftwareDevelopment from "../components/CustomSoftwareDevelopment"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://codefulcrum.com/custom-software-development/"
        />
      </Helmet>
      <CustomSoftwareDevelopment />
    </Layout>
  )
}
