import React from "react"

import Button from "../../shared/Button"
import Heading from "../../shared/Heading"
import "./Hero.scss"
import { Box } from "@mui/system"

export default function Hero({
  btnText,
  img,
  text,
  header,
  id,
  scroll,
  social,
}) {
  return (
    <div
      className="hero-bg"
      id={id}
      data-aos="fade-up"
      data-aos-anchor-placement="top-center"
      data-aos-duration="1000"
    >
      <div className="container position-relative">
        <div className="row pb-4">
          <div className="col-lg-7 col-sm-12 col-xs-12  hero-text-column-cs">
            <div className="line-wrapper">
              <p className="text">Custom Software Development</p>
            </div>
            <Heading variant="58" className="header">
              <h1>{header}</h1>
            </Heading>
            <Box className="hero-text-cs">{text}</Box>

            <Button text={btnText} variant="fill" href="/contact" />
          </div>
          <div className="col-lg-5  col-sm-12  col-xs-12 ">
            <div className="hero-img-cs position-relative">
              <img
                src={img}
                alt="top-hero-image"
                className="img-fluid display-tablet-none"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
