import React from "react"
import "./DevelopmentServices.scss"
import { Box } from "@mui/material"
import Heading from "../../shared/Heading"
import { Grid } from "@mui/material"
import Card from "./Card"
function DevelopmentServices() {
  return (
    <div className="development-services-bg" id="d-services">
      <div className="frame-bg">
        <img
          src="/ProductDesign/frame.png"
          alt="development-service"
          className="img-fluid"
          loading="lazy"
        />
        <div className="container">
          <Box px={{ md: 5, sm: 3, xs: 0 }} className="top-box">
            <Grid container>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                py={1}
                pr={{ md: 4, sm: 2, xs: 0 }}
                className="m-auto"
              >
                <div>
                  <div data-aos="fade-up" data-aos-duration="1200">
                    <p className="header">
                      We make creating your own custom products easier than ever
                    </p>
                  </div>
                  <div data-aos="fade-up" data-aos-duration="1200">
                    <p className="text">
                      Want to create a custom web or mobile application but
                      don't know where to begin? We've got your back. Leverage
                      the full potential of custom development to achieve your
                      goals faster. We provide business-centered solutions that
                      are tailored to each client's specific needs.
                    </p>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} sm={12} mt={{ md: 0, sm: 4, xs: 4 }}>
                <div className="img-wrapper m-auto">
                  <img
                    src="/ProductDesign/col-img.png"
                    alt="product-design-pictures"
                    className="img-fluid"
                    loading="lazy"
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration="1200"
        className="container pt-5 mt-5"
      >
        <Heading variant="40" className="header-bg">
          <h2>Get Clarity, Get Code</h2>
        </Heading>

        <Box>
          <div data-aos="fade-up" data-aos-duration="1200">
            <p className="sub-header">
              Whether you’re a <span className="span-1">Startup, SME</span> or
              an <span className="span-2">Enterprise;</span> we help you clarify
              your needs and code your ideas into successful, market-ready
              products.
            </p>
          </div>
        </Box>
        <Card />
      </div>
    </div>
  )
}

export default DevelopmentServices
